import React from 'react'
import Layout from '../components/layout'
import { Container } from 'reactstrap'

const PrivacyPolicy = () => (
  <Layout>
    <section className="py-5">
      <Container>
        <h1>Privacy Policy</h1>
        <p>
          Greens of Chippenham are committed to protecting your privacy. We collect only the necessary personal information to provide services to you such as window cleaning, solar panel cleaning, gutter cleaning, and other occasional maintenance services.
        </p>
        <p>
          Personal information may include your name, contact details, and service address. This information will be used solely for scheduling appointments, service updates, and billing purposes.
        </p>
        <p>
          Personal data given to Greens of Chippenham will not be sold, rented, or shared with any third party for marketing purposes without the customer's consent.
        </p>
        <p>
          We will take all reasonable measures to safeguard your information. You have the right to access and request corrections to your personal data by contacting us directly.
        </p>
      </Container>
    </section>
  </Layout>
)

export default PrivacyPolicy
